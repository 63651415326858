<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSubElement"
        persistent
        max-width="1000px"
        :retain-focus="false"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sub Element</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSubElement = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ElementChoose></ElementChoose>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogSubElement = false"
              >Đóng</v-btn
            >
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              @click="btnAddSubElement"
              outlined
              >Lưu</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
export default {
  name: "SubElementModal",
  components: {
    ElementChoose: () => import("./ElementChoose"),
  },
  data() {
    return {};
  },
  mixins: [
      constantTestBuilder,
  ],
  computed: {
    ...mapState({
      type_form_element: (state) =>
        state.contentTestBuilderStore.type_form_element,
      model_sub_element: (state) =>
        state.contentTestBuilderStore.model_sub_element,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    dialogSubElement: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSubElement;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSubElement",
          value
        );
      },
    },
    element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementId", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_post_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioData",
          value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementImageData",
          value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementAudioFile",
          value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementImageFile",
          value
        );
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementFormDisplay",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setVideoElementData",
          value
        );
      },
    },
    indexExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexExercise", value);
      },
    },
    model_sub_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.model_sub_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setModelSubElement", value);
      },
    },
    element_pdf_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_pdf_file;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPdfFile", value);
      },
    },
    element_pdf_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_pdf_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPdfData", value);
      },
    },
  },
  watch: {
    dialogSubElement: function (val) {
      if (val == true) {
        this.setDataInput();
      }
    },
  },
  created() {},
  methods: {
    setDataInput() {
      if (this.type_form_element === "add") {
        this.type_element = null;
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.element_id = null;
        this.element_pdf_file = null;
        this.element_pdf_data = null;
      } else {
        let data = [];
        if (this.model_sub_element === "section") {
          data = this.skills[this.indexSkill].sections[this.indexSection];
        } else if (this.model_sub_element === "exercise") {
          data =
            this.skills[this.indexSkill].sections[this.indexSection].exercises[
              this.indexExercise
            ];
        }
        this.type_element = data.sub_element_type;
        this.element_post_data =
          data.sub_element_type === "Post" ? data.sub_element_data : null;
        this.element_image_data =
          data.sub_element_type === "Image" ? data.sub_element_data : null;
        this.element_audio_data =
          data.sub_element_type === "Audio" ? data.sub_element_data : null;
        this.video_element_data =
          data.sub_element_type === "Video" ? data.sub_element_data : null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.video_element_id =
          data.sub_element_type === "Video" ? data.sub_element_id : null;
        this.element_id =
          data.sub_element_type !== "Video" ? data.sub_element_id : null;
        this.element_pdf_file = null;
        this.element_pdf_data =
          data.sub_element_type === "Pdf" ? data.sub_element_data : null;
      }
    },
    validateFormElement() {
      let flat = true;
      if (this.type_element == null) {
        this.$toasted.error("Hãy chọn loại element !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.type_element === "Video") {
        if (this.video_element_id == null) {
          this.$toasted.error("Bạn chưa chọn tệp tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Image") {
        if (this.element_image_data == null) {
          this.$toasted.error("Bạn chưa chọn tập tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Audio") {
        if (this.element_audio_data == null) {
          this.$toasted.error("Bạn chưa chọn tệp tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Pdf") {
        if (this.element_pdf_data == null) {
          this.$toasted.error("Bạn chưa chọn tệp tin !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Post") {
        if (this.element_post_data == null) {
          this.$toasted.error("Bạn chưa nhập dữ liệu !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    async btnAddSubElement() {
      let validate = this.validateFormElement();
      if (!validate) {
        return;
      }
      this.is_call_api = true;
      let seft = this;
      let valueElement = this.getValueElement(this.type_element);
      let url = "";
      if (this.model_sub_element === "section") {
        url = "prep-app/test/draft/section/upload-sub-element";
      } else {
        url = "prep-app/test/draft/exercise/upload-sub-element";
      }
      if (this.type_element === "Video") {
        let formData = new FormData();
        formData.append("type", valueElement);
        formData.append("section_id", seft.section_id);
        formData.append("exercise_id", seft.exercise_id);
        formData.append("sub_element_id", seft.video_element_id);
        await ApiService.post(url, formData).then(function (response) {
          if (response) {
            if (seft.model_sub_element === "section") {
              seft.skills[seft.indexSkill].sections[
                seft.indexSection
              ].sub_element_type = seft.type_element;
              seft.skills[seft.indexSkill].sections[
                seft.indexSection
              ].sub_element_data = seft.video_element_data;
              seft.skills[seft.indexSkill].sections[
                seft.indexSection
              ].sub_element_id = seft.video_element_id;
            } else {
              seft.skills[seft.indexSkill].sections[
                seft.indexSection
              ].exercises[seft.indexExercise].sub_element_type =
                seft.type_element;
              seft.skills[seft.indexSkill].sections[
                seft.indexSection
              ].exercises[seft.indexExercise].sub_element_data =
                seft.video_element_data;
              seft.skills[seft.indexSkill].sections[
                seft.indexSection
              ].exercises[seft.indexExercise].sub_element_id =
                seft.video_element_id;
            }
            seft.$set(seft.skills);
            seft.type_element = null;
            seft.element_data = null;
            seft.dialogSubElement = false;
            seft.is_call_api = false;
          }
        });
      } else {
        let formData = new FormData();
        formData.append("type", valueElement);
        formData.append("section_id", this.section_id);
        formData.append("exercise_id", seft.exercise_id);
        formData.append("sub_element_id", seft.element_id);
        if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
          formData.append("editor", this.checkElementData(this.type_element));
        }
        formData.append("file", this.checkElementFile(this.type_element));
        this.is_call_api = true;
        await ApiService.post(url, formData)
          .then(function (response) {
            if (response) {
              if (seft.model_sub_element === "section") {
                seft.skills[seft.indexSkill].sections[
                  seft.indexSection
                ].sub_element_type = seft.type_element;
                seft.skills[seft.indexSkill].sections[
                  seft.indexSection
                ].sub_element_data = seft.checkElementData(seft.type_element);
                seft.skills[seft.indexSkill].sections[
                  seft.indexSection
                ].sub_element_id = response.data.element_code;
              } else {
                seft.skills[seft.indexSkill].sections[
                  seft.indexSection
                ].exercises[seft.indexExercise].sub_element_type =
                  seft.type_element;
                seft.skills[seft.indexSkill].sections[
                  seft.indexSection
                ].exercises[seft.indexExercise].sub_element_data =
                  seft.checkElementData(seft.type_element);
                seft.skills[seft.indexSkill].sections[
                  seft.indexSection
                ].exercises[seft.indexExercise].sub_element_id =
                  response.data.element_code;
              }
              seft.$set(seft.skills);
              seft.type_element = null;
              seft.element_data = null;
              seft.dialogSubElement = false;
              seft.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              seft.is_call_api = false;
              seft.$toasted.error(
                "Upload not successfully. Please try again or contact admin !!",
                {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                }
              );
              console.log(error);
            }
          });
      }
    },
    checkElementData(type) {
      if (type === "Audio") {
        return this.element_audio_data;
      }
      if (type === "Image") {
        return this.element_image_data;
      }
      if (type === "Post") {
        return this.element_post_data;
      }
      if (type === "Pdf") {
        return this.element_pdf_data;
      }
      return null;
    },
    checkElementFile(type) {
      if (type === "Image") {
        return this.element_image_file;
      }
      if (type === "Audio") {
        return this.element_audio_file;
      }
      if (type === "Pdf") {
        return this.element_pdf_file;
      }
      return null;
    },
    getValueElement(type) {
      let valueElement;
      if (type === "Audio") valueElement = 7;
      else if (type === "Image") valueElement = 2;
      else if (type === "Video") valueElement = 5;
      else if (type === "Embed_yt") valueElement = 4;
      else if (type === "Post") valueElement = 6;
      else if (type === "Pdf") valueElement = 8;
      else if (type === "Album") valueElement = 9;
      return valueElement;
    },
  },
};
</script>

<style></style>
